<template>
  <div class="home">
    <div class="bgTop"></div>

    <Head :centerInfo="true"></Head>
    <div class="subnav">
      <ul>
        <li v-for="(item,index) in rightsubNavList" @click="handleScrollNav(index)" :key="index">{{item}}</li>
      </ul>
    </div>
    <div class="mauto">
      <!--头部-->
      <div class="content">
        <!--banner-->
        <div class="banner" id="newest">
          <div class="banner_box">
            <swiper ref="mySwiper" :options="swiperOptions">
                <swiper-slide
                v-for="(item,index) in imgUrl" :key="index">  
                <!-- $store.commit('JUMP_PLAY',{alid:item.aliVideoId,videoId:item.videoID,vue:this}) -->
                  <img @click="jump(item)" :src="item.coverUrl" alt="">
                </swiper-slide>
                <div class="swiper-pagination" slot="pagination"></div>
              </swiper>
          </div>
          <div class="videoContent">
            <div class="info">
              <div class="info_box" v-for="(item,index) in HotPush" :key="index"
                @click="jump(item)">
                <img :src="item.coverUrl" alt="">
                <img class="itemBox_bg" src="~assets/item_bg.png" alt="">
   
                <p class="videoTitle">{{item.videoTitle}}</p>
                <div class="info_boxHover" :class="item.active=='true'?'restartTop':''">
                  <p>{{item.videoTitle}}</p>
                  <strong>{{item.name}}</strong>
                  <em>{{item.playNum}}播放</em>
                  <span class="iconfont icon-bofang"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--人气排行-->
        <div class="content_box" id="popularity">
          <div class="popularityRanking ranking">
            <div class="ranking_title">
              <img src="~assets/popularity_icon.png" alt="">
              <p>当前共有 {{PopularList.videoCnts}} 视频</p>
              <div @click="jumppopular('/hot')">
                更多 >
              </div>
              <div @click="getPopularListFn(true)">
                <!-- <span class="iconfont icon-icon--"></span> -->
                换一换
              </div>
            </div>
            <div class="content_video">
              <div class="info">
                <div class="info_box" v-for="(item,index) in PopularList.videoDTOPage.records" :key="index"
                  @click="jump(item)">
                  <img :src="item.coverUrl" alt="">
                  <img class="itemBox_bg" src="~assets/item_bg.png" alt="">
                  <p class="videoTitle">{{item.videoTitle}}</p>
                  <div class="info_boxHover" :class="item.active=='true'?'restartTop':''">
                    <p>{{item.videoTitle}}</p>
                    <strong>{{item.name}}</strong>
                    <em>{{item.playNum}}播放</em>
                    <span class="iconfont icon-bofang"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="news">
            <img src="~assets/newsTitle_icon.png" alt="">
            <div class="news_content">
              <div class="news_titile" v-if="consultingList.length!=0" @click="jumpNews(consultingList[0])">
                <!-- <a :href="consultingList[0].link" v-if="consultingList!=null"> -->
                  <img src="~assets/hot_icon.png" alt="">
                  <span>{{consultingList[0].title}}</span>
                  <p>{{consultingList[0].desc1}}</p>
                <!-- </a> -->
              </div>
              <ul>
                <li v-for="(item,index) in consultingList" v-show="index!=0" :key="index" @click="jumpNews(item)">
                  [新闻]{{item.title}}
                </li>
                <!--     <li>
                  <a href="" target="_blank">[新闻]联众电竞与HyperX续签</a>
                </li>
                <li>
                  <a href="" target="_blank">[新闻]联众电竞与HyperX续签</a>
                </li>
                <li>
                  <a href="" target="_blank">[新闻]联众电竞与fsdfsdfsdfsHyperX续签</a>
                </li>
                <li>
                  <a href="" target="_blank">[新闻]联众电竞与HyperX续签</a>
                </li>
                <li>
                  <a href="" target="_blank">[新闻]联众电竞与HyperX续签</a>
                </li>
                <li>
                  <a href="" target="_blank">[新闻]联众电竞与HyperX续签</a>
                </li>-->
              </ul>
            </div>
          </div>
        </div>
        <!--新鲜出炉-->
        <div class="content_box">
          <div class="ranking">
            <div class="ranking_title">
              <img src="~assets/newVideo_icon.png" alt="">
              <p>当前共有 {{NewList.videoCnts}} 视频</p>
              <div @click="jumppopular('/newest')">
                更多 >
              </div>
              <div @click="getNewListFn(true)">
                <!-- <span class="iconfont icon-icon--"></span> -->
                换一换
              </div>
            </div>
            <div class="content_video">
              <div class="info">
                <div class="info_box" v-for="(item,index) in NewList.videoDTOPage.records" :key="index"
                  @click="jump(item)">
                  <img :src="item.coverUrl" alt="">
                  <img class="itemBox_bg" src="~assets/item_bg.png" alt="">
                  <p class="videoTitle">{{item.videoTitle}}</p>
                  <div class="info_boxHover" :class="item.active=='true'?'restartTop':''">
                    <p>{{item.videoTitle}}</p>
                    <strong>{{item.name}}</strong>
                    <em>{{item.playNum}}播放</em>
                    <span class="iconfont icon-bofang"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--升级-->
        <div class="content_box"  v-for="(ite,index) in classVideoTotal" :key="index" >
          <div class="ranking" v-if="ite">
            <div class="ranking_title">
              <img :src="titleIcon[index].url" alt="">
              <p>当前共有 {{ite.videoCnts}} 视频</p>
              <div @click="jumpsecondy(index)">
                更多 >
              </div>
              <div @click="getVideoejPageByClassIDFn(true,index)">
                <!-- <span class="iconfont icon-icon--"></span> -->
                换一换
              </div>
            </div>
            <div class="content_video">
              <div class="info">
                <div class="info_box" v-for="(item,inde) in ite.videoDTOPage.records" :key="inde"
                  @click="jump(item)">
                  <img :src="item.coverUrl" alt="">
                  <img class="itemBox_bg" src="~assets/item_bg.png" alt="">
                  <p class="videoTitle">{{item.videoTitle}}</p>
                  <div class="info_boxHover" :class="item.active=='true'?'restartTop':''">
                    <p>{{item.videoTitle}}</p>
                    <strong>{{item.name}}</strong>
                    <em>{{item.playNum}}播放</em>
                    <span class="iconfont icon-bofang"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
  import Head from '@/components/head.vue';
  import 'swiper/swiper-bundle.css'
  import { swiper, swiperSlide } from 'vue-awesome-swiper' 
  import {
    getHomePush,
    getHotPush,
    getPopularList,
    getNewList,
    getVideosecondyClass,
    getVideoejPageByClassID,
    getConfigNotice
  } from '@/api/authority'
  export default {
    name: "index",
    components: {
      Head,
        swiper,
    swiperSlide
    },
    data() {
      return {
        imgUrl: [],
        titleIcon: [],
        HotPush: [],
        PopularList: {
          videoCnts: 0,
          videoDTOPage: {

          }
        },
      swiperOptions: {
        loop:true,
        observer: true,
        observeParents: true,
        pagination: {
          el: '.swiper-pagination',    //与slot="pagination"处 class 一致
          clickable: true,            //轮播按钮支持点击
          bulletClass: 'my-bullet',
          bulletActiveClass: 'my-bullet-active',
        },
        autoplay : {
            delay : 2000, //自动切换的时间间隔，单位ms
            disableOnInteraction : false //用户操作swiper之后，是否禁止autoplay
        },
     
      },
        NewList: {
          videoCnts: 0,
          videoDTOPage: {

          }
        },
        rightsubNavList: ["热门", "最新"],
        classVideoTotal: [],
        VideosecondyClass: [],
        consultingList: [],
          swiperList:[//banner主图数据
         {img_url: require("../assets/1.png"),title: "文章标题1",id: "1" },
         {img_url: require("../assets/1.png"),title: "文章标题2",id: "2" },
         {img_url: require("../assets/1.png"),title: "文章标题3",id: "3" },
         {img_url: require("../assets/1.png"),title: "文章标题4",id: "4" },
      ],
      }
    },
// computed: {
//    swiper() {
//       return this.$refs.mySwiper.swiper
//    }
// },
    created(){
           // 轮播
      this.getHomePushFn()
    },
    mounted() {
      // this.swiper.slideTo(3, 1000, false)
      window.scrollTo(0,0)
      // 热门视频
      this.getHotPushFn()

      // 人气排行
      this.getPopularListFn()

      // 新鲜出炉
      this.getNewListFn()

      // 视频分类
      this.getVideosecondyClassFn()

      // 咨询
      this.getConfigNoticeFn()
    },
    methods: {
      jump(item){
        this.$store.commit('JUMP_PLAY',{alid:item.aliVideoId,videoId:item.videoID,vue:this})
      },
      jumpNews(item){
        this.$router.push({path: '/consultingService', query: {id: item.id}});
      },
      // 轮播
      getHomePushFn() {
        getHomePush().then(response => {
          if (response.data.code == 0) {
            this.imgUrl = response.data.data
          } else {
            this.$message({
              'message': response.data.msg
            })
          }
        })
      },
      // 资讯
      getConfigNoticeFn() {
        getConfigNotice().then(response => {
          if (response.data.code == 0) {
            this.consultingList = response.data.data
          } else {
            this.$message({
              'message': response.data.msg
            })
          }
        })
      },
      //右侧导航
      handleScrollNav(index) {
        window.scrollTo(0, (index * 502) + 405)
      },
      // 跳转二级分类
      jumpsecondy(index) {
        this.$router.push({
          path: '/classification',
          query: {
            cID: this.VideosecondyClass[index].classID
          }
        })
      },
      // 跳转最新
      jumppopular(url) {
        this.$router.push({
          path: url,
          query: {
            parm: 1
          }
        })
      },
      // 分类
      getVideosecondyClassFn() {
        getVideosecondyClass().then(response => {
          if (response.data.code == 0) {
            this.VideosecondyClass = response.data.data
            for (let i in this.VideosecondyClass) {
              this.rightsubNavList.push(this.VideosecondyClass[i].className)
              this.titleIcon.push({'classId':this.VideosecondyClass[i].classID,'url':this.VideosecondyClass[i].bgUrl})
              this.getVideoejPageByClassIDFn(false, this.VideosecondyClass[i].classID)
            }
          } else {
            this.$message({
              'message': response.data.msg
            })
          }
        })
      },
      // 新鲜出炉
      getNewListFn(type) {
        var current = 1
        var size = 10
        if (type && this.NewList.videoDTOPage.current < this.NewList.videoDTOPage.pages) {
          var current = this.NewList.videoDTOPage.current
          current++
        }
        getNewList({
          current: current,
          size: size
        }).then(response => {
          if (response.data.code == 0) {
            this.NewList = response.data.data

          } else {
            this.$message({
              'message': response.data.msg
            })
          }
        })
      },
      // 分类视频
      getVideoejPageByClassIDFn(type, num) {
        var classId = num
        var current = 1
        var size = 10
        if (type && this.classVideoTotal[num].videoDTOPage.current < this.classVideoTotal[num].videoDTOPage.pages) {
          var current = this.classVideoTotal[num].videoDTOPage.current
          current++
        }
        if (type) {
          classId = this.VideosecondyClass[num].classID
        }
        // console.log('------->')
        // console.log(classId)
        getVideoejPageByClassID({current: current,size: size,classID: classId}).then(response => {
          // console.log('<-------------------')
            // console.log(classId)
            // debugger
              //  console.log(response.data.data.videoCnts)
              //  console.log(response.data.data)
          if (response.data.code == 0) {
            if (type) {
              this.$set(this.classVideoTotal, num, response.data.data)
              // this.classVideoTotal[num] = response.data.data
            } else { 
              for(let i in this.titleIcon){
                if(this.titleIcon[i].classId == classId){

                 this.$set(this.classVideoTotal, i, response.data.data)
                }
              }
            }
          } else {
            this.$message({
              'message': response.data.msg
            })
          }
        })
      },
      // 人气排行
      getPopularListFn(type) {
        var current = 1
        var size = 8
        if (type && this.PopularList.videoDTOPage.current < this.PopularList.videoDTOPage.pages) {
          var current = this.PopularList.videoDTOPage.current
          current++
        }
        getPopularList({
          current: current,
          size: size
        }).then(response => {
          if (response.data.code == 0) {
            this.PopularList = response.data.data
            console.log(this.PopularList)
          } else {
            this.$message({
              'message': response.data.msg
            })
          }
        })
      },
      // 热门视频
      getHotPushFn() {
        getHotPush().then(response => {
          if (response.data.code == 0) {
            this.HotPush = response.data.data
          } else {
            this.$message({
              'message': response.data.msg
            })
          }
        })
      }
    }
  };
</script>
<style lang="less">
  body {
    background: #f8f8fc;
  }
 .swiper-container {
    width: 100%;
    height: 100%;
  }
.my-bullet{
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color: #ffffff;
        opacity: 1;
        cursor: pointer;
        // float:left;
        display: inline-block;
    
  }
  .my-bullet+.my-bullet{
    margin-left:4px;
  }
  .my-bullet-active{
    background: #FD882E;
  }
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    height: 200px;
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }
  .swiper-pagination{
    bottom:28px;
    width:100%;
  }
  .home {
    padding-bottom: 90px;
    overflow: hidden;
    .swiper-slide{
      height:376px;
    }
    .bgTop {
      height: 481px;
      position: absolute;
      top: 0px;
      left: 0px;
      z-index: -9;
      width: 100%;
      background: white;
    }

    .subnav {
      width: 68px;
      // height: 262px;
      background-color: #ffffff;
      border-radius: 9px;
      border: solid 1px #ebebeb;
      position: fixed;
      left: calc(((100% - 1200px) / 2) + 1220px);
      top: 90px;
      z-index: 999;

      li {
        height: 42px;
        line-height: 42px;
        cursor: pointer;
        text-align: center;
        color: #999999;
      }
    }

    .content {
      overflow: hidden;
      padding-top: 90px;
    }

    .banner_box {
      width: 469px;
      float: left;
      height: 376px;
      border-radius: 9px;
      overflow: hidden;
      position: relative;
      // .swiper-slide{
      img {
        width: 469px;
        height: 376px;
        cursor: pointer;
        object-fit: fill;
        // object-fit: ;
      }

      .el-carousel__button {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color: #ffffff;
        opacity: 1;
      }

      .is-active .el-carousel__button {
        background-color: #ff8a00;
      }

      // }
    }

    .videoContent {
      float: right;
      width: 734px;
      margin: -20px 0px 0px -20px;
      overflow: hidden;
      height: 405px;
    }

    .content_box {
      width: 100%;
      padding-top: 50px;
      clear: both;

      .ranking {
        .content_video {
          margin: -20px 0px 0px -20px;
          height: 432px;
        }

        width:100%;

        .ranking_title {
          height: 20px;
          padding-bottom: 20px;

          >img {
            float: left;

          }

          >p {
            color: #666666;
            line-height: 23px;
            padding-left: 20px;
            float: left;
            font-size: 12px;
          }

          div {
            float: right;
            border: 2px solid #8f8f8f;
            color: #8f8f8f;
            text-align: center;
            width: 70px;
            height: 20px;
            line-height: 20px;
            font-size: 12px;
            font-weight: 700;
            margin-top: 4px;
            cursor: pointer;
            &:hover {
              color: #ff8a00;
              border: 2px solid #ff8a00;
            }
          }

          div+div {
            margin-right: 10px;
          }
        }
      }

      .popularityRanking {
        width: 960px;
        float: left;
      }

      .news {
        float: right;
        width: 224px;

        .news_content {
          margin-top: 22px;
          width: 224px;
          height: 375px;
          background-color: #ffffff;
          border-radius: 9px;
          padding: 0px 14px;
          box-sizing: border-box;

          .news_titile {
            padding-top: 24px;
            padding-bottom: 15px;
            border-bottom: 1px solid #ebebeb;
            cursor: pointer;
                font-size: 12px;
              color: #999999;
            // a {
          

              >img {
                vertical-align: middle;
              }

              >span {
                font-size: 14px;
                padding-left: 2px;
              }

              p {
                color: #999999;
                font-size: 12px;
                clear: both;
                line-height: 20px;
                padding-top: 8px;

                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3;
                overflow: hidden;
                width: 194px;
              // }
            }
          }

          ul {
            li {
              margin-top: 18px;
               width: 194px;
                overflow: hidden;
                display: block;
                text-overflow: ellipsis;
                white-space: nowrap;
                color: #999999;
                font-size: 12px;
                cursor: pointer;
              a {
             
              }

              &:hover  {
                color: #f56258;
              }
            }
          }
        }
      }
    }
  }
</style>